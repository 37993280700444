import { TProduct } from '@components/types';
import {
    getFirst,
    getImageObject,
    getLength,
    getSafeUrl,
    humanizeCurrency,
    sanityIoImageLoader,
    trimToLength
} from '@core/utils';
import Image from 'next/image';
import Link from 'next/link';

type Props = {
    product: TProduct;
};

export const BrandProductCard = ({ product }: Props) => {
    return (
        <div className="relative flex flex-col flex-shrink-0 w-40 min-h-52 max-h-60 gap-2 overflow-hidden">
            <div className="flex justify-center max-h-40">
                <Link href={`/product/${product.id}/${getSafeUrl(product.name)}`} prefetch={false}>
                    <Image
                        loader={sanityIoImageLoader}
                        src={getImageObject(product.images)?.url}
                        alt={getImageObject(product.images)?.description || product.name || 'Product'}
                        width="150"
                        height="150"
                        className="flex items-center max-h-60 object-cover"
                    />
                </Link>
            </div>
            <div className="flex flex-col grow justify-between leading-tight font-manrope overflow-hidden">
                <Link href={`/product/${product.id}/${getSafeUrl(product?.name)}`} prefetch={false}>
                    <div className="font-semibold text-ellipsis text-sm text-neutral-700 font-manrope line-clamp-2">
                        {trimToLength(product?.name, 40)}
                    </div>
                </Link>

                {getLength(product?.inventories) > 0 ? (
                    <div className="flex flex-row justify-between items-center">
                        <div className="price">
                            <span className="text-neutral-700 font-semibold lg:text-base text-sm">
                                {humanizeCurrency(
                                    getFirst(product?.inventories)?.price || product?.mrp
                                )}
                            </span>
                            {/* <span className="font-bold text-success-300">{product.offer}</span> */}
                        </div>
                        <div className="flex items-center justify-center text-xs font-medium line-clamp-1 text-neutral-500">
                            {product?.variants?.length} variants
                        </div>
                    </div>
                ) : (
                    <p className="text-error-400 text-sm">Unavailable</p>
                )}

            </div>
        </div>
    );
};
